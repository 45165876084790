const UPDATE_CONFIG = (state, data) => {
	console.log('%cMutation %c – update config object!', 'color: mediumpurple', 'color: mediumpurple')
	state.config = data.topics
}

const UPDATE_ONLINE = (state, data) => {
	console.log('%cMutation %c – update online state!', 'color: mediumpurple', 'color: mediumpurple')
	state.isOnline = data
}

const UPDATE_OFFLINE = (state, data) => {
	console.log('%cMutation %c – update offline state!', 'color: mediumpurple', 'color: mediumpurple')
	state.isOffline = data
}

const UPDATE_LOADER = (state, data) => {
	if (data.minimumTimeLoaded && data.asyncLoaded) {
		console.log('%cMutation %c – update loader based on time & async!', 'color: mediumpurple', 'color: mediumpurple')
		state.loaded = true
	}
}

export default {
	UPDATE_CONFIG,
	UPDATE_ONLINE,
	UPDATE_OFFLINE,
	UPDATE_LOADER,
}
