<template>
	<header class="position-relative top-0 left-0 w-100 p-4 d-flex justify-content-end">
		<img src="../assets/images/brainlab-logo-white.svg" alt="Brainlab Logo" class="position-relative me-3" height="21" />
	</header>
</template>

<script>
export default {
	name: 'Header',
	components: {},
	props: [],
	setup() {
		return {}
	},
}
</script>
