import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Selection from '../views/Selection.vue'
import Header from '../components/Header.vue'

const routes = [
	{
		path: '/',
		name: 'Selection',
		components: {
			default: Selection,
			header: Header,
		},
	},
	{
		path: '/:uid',
		name: 'Dashboard',
		props: true,
		components: {
			default: Dashboard,
			header: Header,
		},
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

export default router
