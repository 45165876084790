<template>
	<div>
		<router-view name="header" />
		<main class="position-relative p-4 md:p-8">
			<router-view />
		</main>
		<loader :loaded="loaded" />
	</div>
</template>

<style lang="scss">
@import 'src/assets/scss/main';
</style>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import Loader from '@/components/Loader.vue'

export default {
	name: 'App',
	components: {
		Loader,
	},
	props: [],
	setup() {
		const store = useStore()
		const loaded = computed(() => store.state.config.loaded)

		const onlineHandler = () => {
			store.commit('config/UPDATE_ONLINE', true)
			store.commit('config/UPDATE_OFFLINE', false)
		}

		const offlineHandler = () => {
			store.commit('config/UPDATE_ONLINE', false)
			store.commit('config/UPDATE_OFFLINE', true)
		}

		if (window.navigator.onLine) {
			onlineHandler()
		} else {
			offlineHandler()
		}

		window.addEventListener('online', onlineHandler)
		window.addEventListener('offline', offlineHandler)

		return { loaded }
	},
}
</script>
