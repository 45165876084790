<template>
  <transition name="fade">
    <div v-if="!loaded" class="loader position-fixed top-0 left-0 bottom-0 right-0 w-100 h-100 bg-black p-4 d-flex justify-content-center align-items-center">
      <figure class="block m-0 p-0"><img src="../assets/images/brainlab-signet.svg" alt="Brainlab Signet" class="loader-logo"></figure>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'Header',
  components: {},
  props: ['loaded'],
  setup() {
    return {
    };
  },
};
</script>
