<template>
	<div class="d-grid gap-2 col-6 mx-auto">
		<h2>Please select your setup</h2>
		<router-link :to="`/${index}`" v-for="(item, index) in config" :key="index" class="button">
			<span style="font-size: 1.3rem; font-weight: bold">{{ item.title }}</span>
			<br />
			{{ item.description }}
		</router-link>
	</div>
</template>

<script>
// @ is an alias to /src
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
	name: 'Selection',
	setup() {
		const store = useStore()
		const config = computed(() => store.state.config.config)
		store.dispatch('config/fetchConfig')

		return {
			config,
		}
	},
}
</script>
<style>
.button {
	padding: 10px 14px;
	color: #fff;
	text-decoration: none;
	border: 1px solid #fff;
	border-radius: 3px;
}
.button:hover {
	color: #fff;
	background-color: rgba(255, 255, 255, 0.5);
}
</style>
