import axios from 'axios'

const fetchConfig = async ({ commit }) => {
	console.log('%cAction %c – get JSON config!', 'color: yellow', 'color: yellow')

	let minimumTimeLoaded = false
	let asyncLoaded = false

	const requestData = {
		method: 'GET',
		url: `/config.json?v=${new Date().toISOString()}`,
	}

	setTimeout(() => {
		minimumTimeLoaded = true
		commit('UPDATE_LOADER', { minimumTimeLoaded, asyncLoaded })
	}, 2000)

	axios(requestData)
		.then((response) => {
			if (response.status === 200) {
				commit('UPDATE_CONFIG', response.data)
				asyncLoaded = true
				commit('UPDATE_LOADER', { minimumTimeLoaded, asyncLoaded })
			}
		})
		.catch((error) => {
			console.log('%cError %c – fetching config failed.', 'color: red')
			console.log(error.response)
		})
}

export default {
	fetchConfig,
}
