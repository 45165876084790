<template>
	<div>
		<div class="grid align-items-start">
			<div
				v-for="(item, index) in tiles"
				:key="index"
				@click="showModal(index)"
				class="content--tile position-relative g-col-12 g-col-sm-6 g-col-lg-4">
				<div class="ratio ratio-16x9">
					<img :src="item.cover" :alt="item.headline" class="d-block w-100" />
				</div>
				<div class="tile__description text-primary fw-bold position-absolute mx-3 mt-3 top-0">
					<h2 class="tile__headline lh-1" v-html="item.headline"></h2>
					<small class="d-block fs-6 fw-light text-white" v-html="item.subline"></small>
				</div>
				<span class="tile__category text-white fs-6 position-absolute bottom-0 mb-2 ms-3">{{ item.category }}</span>
			</div>
		</div>

		<div class="modal-backdrop fade show" v-if="modalActive"></div>
		<Modal
			v-if="tiles && modalActive"
			v-show="modalActive"
			@close="closeModal"
			@click.self="closeModal"
			:title="tiles[activeIndex].headline"
			:class="{ show: modalActive }">
			<template v-slot:body>
				<video
					v-if="tiles[activeIndex].type === 'video'"
					id="modal-video"
					class="w-100 h-auto"
					width="16"
					height="9"
					autoplay
					muted
					controls
					ref="modalVideo">
					<source :src="tiles[activeIndex].src" type="video/mp4" />
				</video>

				<iframe
					v-else-if="tiles[activeIndex].type === 'presentation'"
					:src="tiles[activeIndex].src"
					width="100%"
					height="500px"></iframe>

				<div class="d-flex flex-column" v-else-if="tiles[activeIndex].type === 'demo'">
					<a v-if="!isOffline" :href="tiles[activeIndex].src" target="_blank" class="px-3 py-5 d-inline-flex h4 mx-auto my-0">
						Open demo for {{ tiles[activeIndex].headline }}
					</a>
					<span v-else class="h4 py-5 mx-auto my-0"> Scan now </span>

					<vue-qrious class="qr--wrapper mx-auto my-0" :value="tiles[activeIndex].src" :size="300" />
				</div>
			</template>
		</Modal>
	</div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import VueQrious from 'vue-qrious'
import Modal from './Modal.vue'

export default {
	name: 'Tiles',
	props: ['tiles'],
	components: {
		Modal,
		VueQrious,
	},
	setup() {
		const modalVideo = ref(null)
		const modalActive = ref(false)
		const activeIndex = ref(0)
		const store = useStore()

		function closeModal() {
			modalActive.value = false

			if (modalVideo.value !== null) {
				modalVideo.value.pause()
				modalVideo.value.currentTime = 0
			}
		}

		function showModal(index) {
			activeIndex.value = index
			modalActive.value = true

			requestAnimationFrame(() => {
				const videoInModal = modalVideo.value

				if (videoInModal !== null) {
					if (videoInModal.requestFullscreen) {
						videoInModal.requestFullscreen()
					} else if (videoInModal.mozRequestFullScreen) {
						videoInModal.mozRequestFullScreen()
					} else if (videoInModal.webkitRequestFullscreen) {
						videoInModal.webkitRequestFullscreen()
					} else if (videoInModal.msRequestFullscreen) {
						videoInModal.msRequestFullscreen()
					}
				}
			})
		}

		return {
			modalActive,
			activeIndex,
			showModal,
			closeModal,
			modalVideo,
			isOnline: computed(() => store.state.config.isOnline),
			isOffline: computed(() => store.state.config.isOffline),
		}
	},
}
</script>

<style scoped>
.content--tile {
	cursor: pointer;
}

.qr--wrapper {
	height: auto;
	width: 300px;
}
.g {
	color: blue;
}
</style>
