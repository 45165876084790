<template>
	<div>
		<nav class="mb-4">
			<div class="d-flex position-relative fs-2 p-0 m-0 mb-6">
				<template v-for="(item, index) in config">
					<span v-if="item.entries.length" :key="index" :class="{ 'text-primary': index === activeIndex }" class="pe-4">
						<span @click="changeIndex(index)">{{ item.title }}</span>
					</span>
				</template>
			</div>
		</nav>
		<div class="content">
			<tiles :tiles="currentEntries" />
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import Tiles from '@/components/Tiles.vue'

export default {
	name: 'Dashboard',
	components: {
		Tiles,
	},
	setup() {
		const activeIndex = ref(0)
		const store = useStore()
		const route = useRoute()
		const config = computed(() => store.state.config.config[route.params.uid]?.content)
		const currentEntries = computed(() => (config.value ? config.value[activeIndex.value]?.entries : []))
		//console.log(config)
		store.dispatch('config/fetchConfig')

		// Previous Step/Page
		function changeIndex(index) {
			activeIndex.value = index
		}

		return {
			activeIndex,
			config,
			currentEntries,
			changeIndex,
		}
	},
}
</script>
