<template>
	<div class="modal fade" id="tileModal">
		<div class="modal-dialog modal-dialog-centered modal-fullscreen">
			<div class="modal-content bg-black">
				<div class="modal-header left-0 top-0 bg-gradient w-100">
					<h5 class="modal-title" v-html="title"></h5>
					<button
						type="button"
						class="btn-close btn-close-white"
						data-bs-dismiss="modal"
						aria-label="Close"
						@click="$emit('close')"></button>
				</div>
				<div class="modal-body p-0">
					<slot name="body"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Modal',
	props: {
		config: {
			type: Array,
		},
		title: {
			type: String,
		},
	},
}
</script>
