import Vuex from 'vuex'

import config from './modules/config'

export default Vuex.createStore({
	state: {
		version: '2.2',
	},
	modules: {
		config,
	},
	strict: process.env.NODE_ENV !== 'production',
})
